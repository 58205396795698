import React from 'react'
import ReactDOM from 'react-dom'
import Parse from 'parse'
import ReactGA from 'react-ga4'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import Routes from './routes'
import reportWebVitals from './reportWebVitals'
import './styles/global.scss'

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID)

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1,
  })
}

Parse.initialize(
  process.env.REACT_APP_PARSE_APPLICATION_ID,
  process.env.REACT_APP_PARSE_SERVER_JAVASCRIPT_KEY
)

Parse.serverURL = process.env.REACT_APP_PARSE_PUBLIC_SERVER_URL

ReactDOM.render(
  <React.StrictMode>
    <Routes />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
