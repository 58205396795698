import React from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as Logo } from '../../../../assets/logo.svg'
import {
  CONTACTS_ROUTE,
  DOWNLOAD_ROUTE,
  ROOT_ROUTE,
} from '../../../../const/routes'
import styles from './header.module.scss'

const Header = () => (
  <div className={styles.menu}>
    <Link to={ROOT_ROUTE}>
      <Logo />
    </Link>
    <div className={styles.menuElement}>
      <ul>
        <li>
          <Link
            to={{
              pathname: ROOT_ROUTE,
              state: { id: 'how', offset: -100 },
            }}
          >
            How it works
          </Link>
        </li>
        <li>
          <Link
            to={{
              pathname: ROOT_ROUTE,
              state: { id: 'about', offset: -100 },
            }}
          >
            About the product
          </Link>
        </li>
        <li>
          <Link
            to={{
              pathname: ROOT_ROUTE,
              state: { id: 'pricing', offset: -30 },
            }}
          >
            Pricing
          </Link>
        </li>
        <li>
          <Link to={CONTACTS_ROUTE}>Contact</Link>
        </li>
      </ul>
    </div>
    <div className={styles.downloadLink}>
      <Link to={DOWNLOAD_ROUTE} type="button">
        <button type="button">Download</button>
      </Link>
    </div>
  </div>
)

export default Header
