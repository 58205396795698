import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'

const Loader = ({ title }) => (
  <div className={styles.loader}>
    <div className={styles.animation}>
      <div className={styles.braces}>
        <span>{'{'}</span>
        <span className={styles.insideText}>{title}</span>
        <span>{'}'}</span>
      </div>
    </div>
  </div>
)

Loader.propTypes = {
  title: PropTypes.string.isRequired,
}

export default Loader
