import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { slide as Menu } from 'react-burger-menu'
import { Link } from 'react-router-dom'
import { CONTACTS_ROUTE, DOWNLOAD_ROUTE, ROOT_ROUTE } from '../../const/routes'
import styles from './styles.module.scss'
import Footer from './components/Footer'
import Header from './components/Header'

const App = ({ children }) => {
  const [isMenuOpen, handleMenu] = useState(false)

  const handleCloseMenu = () => {
    handleMenu(false)
  }

  const handleStateChange = (state) => {
    handleMenu(state.isOpen)
  }

  return (
    <div className={styles.app}>
      <Menu right isOpen={isMenuOpen} onStateChange={handleStateChange}>
        <Link
          to={DOWNLOAD_ROUTE}
          type="button"
          onClick={() => handleCloseMenu()}
        >
          <button type="button">Download</button>
        </Link>
        <Link
          to={{
            pathname: ROOT_ROUTE,
            state: { id: 'how', offset: -100 },
          }}
          onClick={() => handleCloseMenu()}
        >
          How it works
        </Link>
        <Link
          to={{
            pathname: ROOT_ROUTE,
            state: { id: 'about', offset: -100 },
          }}
          onClick={() => handleCloseMenu()}
        >
          About the product
        </Link>
        <Link
          to={{
            pathname: ROOT_ROUTE,
            state: { id: 'pricing', offset: -30 },
          }}
          onClick={() => handleCloseMenu()}
        >
          Pricing
        </Link>
        <Link to={CONTACTS_ROUTE} onClick={() => handleCloseMenu()}>
          Contact
        </Link>
      </Menu>
      <header className={styles.menuWrapper}>
        <Header />
      </header>
      <main>{children}</main>
      <footer>
        <Footer />
      </footer>
    </div>
  )
}

App.propTypes = {
  children: PropTypes.node.isRequired,
}

export default App
