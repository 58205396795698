import React, { Suspense, lazy } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import AppContainer from './containers/AppContainer'
import Loader from './components/Loader'
import {
  CHECKOUT_SUCCESS_ROUTE,
  CONTACTS_ROUTE,
  DOWNLOAD_ROUTE,
  PURCHASE_FAIL_ROUTE,
  PURCHASE_ROUTE,
  RESTORE_ROUTE,
  ROOT_ROUTE,
  TERMS_ROUTE,
  SECURITY_ROUTE,
  END_USER_ROUTE,
} from './const/routes'

const MainPage = lazy(() => import('./pages/MainPage'))
const PurchasePage = lazy(() => import('./pages/PurchasePage'))
const PurchaseSuccessPage = lazy(() =>
  import('./pages/PurchasePage/PurchaseSuccessPage')
)
const PurchaseFailPage = lazy(() =>
  import('./pages/PurchasePage/PurchaseFailPage')
)

const DownloadPage = lazy(() => import('./pages/DownloadPage'))
const RestorePage = lazy(() => import('./pages/RestorePage'))
const ContactPage = lazy(() => import('./pages/ContactPage'))
const TermsPage = lazy(() => import('./pages/TermsPage'))
const SecurityPage = lazy(() => import('./pages/SecurityPage'))
const EndUserPage = lazy(() => import('./pages/EndUserPage'))
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'))

const Routes = () => (
  <Router>
    <AppContainer>
      <Suspense fallback={<Loader title="Loading..." />}>
        <Switch>
          <Route exact component={MainPage} path={ROOT_ROUTE} />
          <Route exact component={DownloadPage} path={DOWNLOAD_ROUTE} />
          <Route exact component={PurchasePage} path={PURCHASE_ROUTE} />
          <Route
            exact
            component={PurchaseSuccessPage}
            path={CHECKOUT_SUCCESS_ROUTE}
          />
          <Route
            exact
            component={PurchaseFailPage}
            path={PURCHASE_FAIL_ROUTE}
          />
          <Route exact component={RestorePage} path={RESTORE_ROUTE} />
          <Route exact component={ContactPage} path={CONTACTS_ROUTE} />
          <Route exact component={TermsPage} path={TERMS_ROUTE} />
          <Route exact component={SecurityPage} path={SECURITY_ROUTE} />
          <Route exact component={EndUserPage} path={END_USER_ROUTE} />
          <Route component={NotFoundPage} />
        </Switch>
      </Suspense>
    </AppContainer>
  </Router>
)

export default Routes
