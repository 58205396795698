export const ROOT_ROUTE = '/'
export const DOWNLOAD_ROUTE = '/download'
export const PURCHASE_ROUTE = '/purchase'
export const CHECKOUT_SUCCESS_ROUTE = '/checkout/success'
export const PURCHASE_FAIL_ROUTE = '/checkout/fail'
export const CONTACTS_ROUTE = '/contact'
export const RESTORE_ROUTE = '/restore'
export const TERMS_ROUTE = '/terms'
export const SECURITY_ROUTE = '/security'
export const END_USER_ROUTE = '/eula'
