import React from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as LinkedIn } from '../../../../assets/linkedin.svg'
import { ReactComponent as Twitter } from '../../../../assets/twitter.svg'
import {
  END_USER_ROUTE,
  SECURITY_ROUTE,
  TERMS_ROUTE,
} from '../../../../const/routes'
import styles from './footer.module.scss'

const Footer = () => (
  <div className={styles.wrapper}>
    <div className={styles.left}>
      <span>Copyright Ⓒ 2021 Package Updater</span>
      <ul>
        <li>
          <Link to={TERMS_ROUTE}>Terms of service</Link>
        </li>
        <li>
          <Link to={SECURITY_ROUTE}>Security</Link>
        </li>
        <li>
          <Link to={END_USER_ROUTE}>EULA</Link>
        </li>
      </ul>
    </div>
    <div className={styles.right}>
      <a href="https://twitter.com/PkgUpdater" target="_blank">
        <Twitter />
      </a>
      <a
        href="https://www.linkedin.com/company/package-updater/"
        target="_blank"
      >
        <LinkedIn />
      </a>
    </div>
  </div>
)

export default Footer
